
/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { isMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";
import FileUploadList from "@/components/scope/file-upload-list.vue";

const app = namespace("app");
const base = namespace("base");
const wxcpSetTagTask = namespace("wxcpSetTagTask");

@Component({
  components: { FileUpload, Editor, FileUploadList }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @app.Mutation setPBPX;

  @wxcpSetTagTask.Action taskCreate;

  emptyToLine = emptyToLine;
  dayjs = dayjs;

  defaultUserTaskFixTemplateUrl: string = "https://speedbiz-saas-public.oss-cn-beijing.aliyuncs.com/dev/speedbiz/others/importTemplate/wxcpSetTagUser.xlsx";

  get breadData() {
    return [
      { name: "企微用户打标", path: "/wxcpSetTagTask/list" },
      {
        name: "添加任务"
      }
    ];
  }

  tagGroupList:any =[]

  taskForm: any = {
    tagId: "",
    tagName: "",
    tagGroupId: "",
    tagGroupName: "",
    importType: 1,
    importValue: ""
  };

  taskFormRules: any = {
    tagId: [
      { required: true, message:"请选择标签"}
    ],
    importType: { required: true, message: "请选择用户导入类型"},
    importValue: { required: true, message: "请输入"}
  }

  excelFileList: []

  created() {
    this.setPBPX(52);
    this.init();
  }
  get taskNo() {
    return this.$route.params.taskNo;
  }

  fillingTag () {
    this.tagGroupList.forEach(group => {
      group.tag.forEach(tag => {
        if (tag.id === this.taskForm.tagId) {
          this.taskForm.tagName = tag.name;
          this.taskForm.tagGroupId = group.groupId;
          this.taskForm.tagGroupName = group.groupName;
        }
      })
    })
  }

  // 标签列表
  getTagList(){
    this.$store.dispatch('wxcpSetTagTask/tagList',{}).then(res => {
      if(res.code == 0){
        this.tagGroupList = res.data.tagGroupList;
      }
    })
  }

  init() {

    this.getTagList();
  }
  mounted() {}

  // 刷新标签
  refreshTagList() {
    this.$store.dispatch('wxcpSetTagTask/tagList',{isRefresh: 1}).then(res => {
      if(res.code == 0){
        this.tagGroupList = res.data.tagGroupList;
      }
    })
  }

  saveTask() {
    (this.$refs.taskForm as any).validate(valid => {
      if(valid){
        this.fillingTag()
        this.taskCreate({
          ...this.taskForm,
          }).then(res => {
          if(res.code==0){
            this.$router.go(-1);
          }else{
            this.$message.error('创建失败，请稍后重试！')
          }
        })
      }
      return
    });
  }
}
