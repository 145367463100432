
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { getUuid } from "@/utils/common";
const base = namespace("base");

@Component({
  model: {
    prop: "fileUrls",
    event: "change"
  }
})
export default class FileUploadList extends Vue {
  @base.Action getUploadToken;
  @Prop({ default: 1 })
  maxNum: number;

  @Prop({ default: "image" }) // image video
  fileType: string;

  @Prop({ default: () => [] })
  defaultFileList: any;

  @Prop({ default: false, type: Boolean })
  readonly: boolean;

  @Prop({ default: () => [] })
  fileUrls: any;

  @Prop({
    default: () => ({ businessCode: "defalut", businessType: "4" })
  })
  uploadBusinessInfo: any;
  isLoading: boolean = false;
  get allUrl() {
    return this.fileList.map(item => item.url);
  }
  uploadUrl: string = "";
  uploadTokenObj: any = {};
  fileList: any = [];
  uploadFileCb(file) {
    return this.getUploadToken({
      businessType: "0",
      businessCode: "000000000"
    }).then(data => {
      this.uploadTokenObj = {
        url: data.data.host,
        key: `${data.data.dir}/${getUuid(15)}/${file.name}`,
        policy: data.data.policy,
        OSSAccessKeyId: data.data.accessId,
        Signature: data.data.signature,
        success_action_status: 200,
        domain: data.data.domain
      };
      this.uploadUrl = data.data.host;
    });
  }
  async beforeAvatarUpload(file) {
    this.isLoading = true;
    await this.uploadFileCb(file);
  }

  handleRemoveFile(file, fileList) {
    if (file.status === "success") {
      const index = this.fileList.findIndex(item => item.uid === file.uid);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
      this.$emit("removeSuccess", index);
      this.emitChange();
      this.isLoading = false;
    }
  }

  handleUploadSuccess(response, file, fileList) {
    if (file.status === "success") {
      this.$emit("uploadSuccess", this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key);
      this.fileList.push({
        name: file.name,
        url: this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key
      });
      if (this.maxNum) {
        if (this.fileList.length >= this.maxNum) {
          this.fileList.splice(0, this.fileList.length-this.maxNum)
        }
      }
      this.emitChange();
      this.isLoading = false;
    }
  }
  handleDeleteImg(i) {
    this.fileList.splice(i, 1);
    this.emitChange();
  }
  emitChange() {
    console.log("maxNum=" + this.maxNum)
    console.log("fileList.length=" + this.fileList.length)
    if (this.maxNum <= 1) {
      if (this.fileList.length > 0) {
        this.$emit("change", this.fileList[0].url);
      } else {
        this.$emit("change", "");
      }
    } else {
      this.$emit(
        "change",
        this.fileList.map(item => item.url)
      );
    }
  }
  defaultUrl: any = [];
  @Watch("defaultFileList", {
    immediate: true
  })
  watchDefaultFileList(val) {
    if (val.length) {
      val.forEach(item => {
        if (item.url && !this.defaultUrl.includes(item.url)) {
          console.log("[ this.fileList ]", this.fileList);
          this.fileList.push(item);
          this.defaultUrl.push(item.url);
          this.emitChange();
        }
      });
    } else {
      this.fileList = [];
      this.defaultUrl = [];
      this.emitChange();
    }
  }
  clearFiles() {
    this.fileList = [];
  }

}
